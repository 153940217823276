<template>
  <div>
    <Card>
      <template #header>Dosya Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addFileModal = true"
              >Dosya Ekle</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Dosyalar Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Dosya Ekle</h4>
      </template>
      <div class="form-group">
        <label for="aciklama">Açıklama</label>
        <input type="text" class="form-control" id="aciklama" />
      </div>
      <div class="form-group">
        <label for="type">Belge Tipi</label>
        <select name="" id="type" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="">Diğer</option>
        </select>
      </div>
      <b-form-group class="mb-0">
        <label for="file">
          Dosya
          <b-button
            v-b-tooltip.hover
            title="Tooltip directive content"
            variant="outline-light"
            size="sm"
          >
            <i class="flaticon2-exclamation"></i>
          </b-button>
        </label>
        <b-form-file
          id="file"
          class="rounded-sm"
          placeholder="Dosya seçiniz"
          drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
        ></b-form-file>
      </b-form-group>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation modalid="dosyalar"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      addFileModal: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Aciklama", value: "aciklama" },
        { text: "Dosya Tipi", value: "tip" },
        { text: "Tarih", value: "tarih" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {

      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `user/list?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.success == true && res.count > 0) {
            this.items = res.data;
            this.totalItems = res.count;

            this.pagination = {
              current_page: res.page,
              total_pages: res.pageCount,
              total_items: res.count,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperationdosyalar");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed:{
    ...mapGetters(["myUser"])
  }
};
</script>
